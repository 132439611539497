import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/core/services/base.service';
import { SIPdetailsResponse, TransferCallPayload, UserStatusUpdateRequest, callDetailsResponse } from '../models/call-center.models';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { DataFilterResponse } from 'app/core/models/grid-filter.models';

@Injectable({
  providedIn: 'root'
})
export class CallCenterService extends BaseService {


  public isalwaysOpen:boolean= false;

  public isalwaysOpenAddContact:boolean= false;

  public isOnGoingStForHold: boolean = false;
  

  public isOnGoingSoForHold: boolean = false;

  public openIncomingDetPopup = new BehaviorSubject(false)

 // public agentStatusChange: Subject<{status:any,updateStatus:boolean}> = new Subject<{status:any,updateStatus:boolean}>();

  public isOpenCallCenter : boolean = false

  //public isActiveCallInbound : boolean = false

 // public isActiveCallOutbound : boolean = false

  constructor(private http: HttpClient) { super(); }

  /**
   * 
   * this api return connection credentials and agent details
   */

  getAgentConnectionDetById() : Observable<HttpResponse<DataFilterResponse<SIPdetailsResponse>>> {
    
    return this.http.get<DataFilterResponse<SIPdetailsResponse>>(this.baseURL+`/call/connect/${this.userId}/user/`, { observe: 'response' });
  }

  getCallDetails(callerId:string) : Observable<HttpResponse<DataFilterResponse<callDetailsResponse>>> {

    return this.http.get<DataFilterResponse<callDetailsResponse>>(this.baseURL+`/calls/${callerId}`,{ observe: 'response' });
  }

  // updateLastCallTime(sipdetailsresponse: UserStatusUpdateRequest) {

  //   return this.http.patch(this.baseURL+`/sip/${this.userId}/user/`, sipdetailsresponse);
  // }

  // updateUserStatus(bosipdetailsresponse: UserStatusUpdateRequest)
  // {
  //   return this.http.patch<DataFilterResponse<any>>(this.baseURL+`/users/${this.userId}/`,bosipdetailsresponse)
  // }

  updateUserStatus(bosipdetailsresponse: UserStatusUpdateRequest): Observable<HttpResponse<DataFilterResponse<any>>> {
    return this.http.patch<DataFilterResponse<any>>(
        `${this.baseURL}/users/${this.userId}/`,
        bosipdetailsresponse,
        { observe: 'response' } // Observe the full HTTP response
    ).pipe(
        map((response: HttpResponse<DataFilterResponse<any>>) => {
            // Return the full HTTP response for further processing
            return response;
        })
    );
}


  getNonCallCenterDetails(toNumber: string, fromNumber:string) {
    return this.http.get<DataFilterResponse<callDetailsResponse>>(this.baseURL+`/did/call_center/disable/${toNumber}/${fromNumber}/`);
  }

  putCallOnHold(body: { call_sid: string, to_number:string }): Observable<HttpResponse<DataFilterResponse<any>>> {  

    // let headers = new HttpHeaders();
    // headers= headers.set('content-type', 'application/json');
   return this.http.post<DataFilterResponse<any>>(this.webhookURL+`/hold`,body, { observe: 'response' });
   // return this.http.post(`https://9f91-202-88-254-119.ngrok-free.app/api/pbx/hold?call_type=`+type,body);

   
  }

  releaseCallFromHold( body: { call_sid: string, to_number:string, sip_username:string, call_center_enabled:boolean} ):Observable<HttpResponse<DataFilterResponse<any>>>{
    return this.http.post<DataFilterResponse<any>>(this.webhookURL+`/unhold`,body, { observe: 'response' });
  }

  endCallFromHold(body: { call_sid: string, to_number:string,username:string}) : Observable<HttpResponse<DataFilterResponse<any>>>{
    
    return this.http.post<DataFilterResponse<any>>(this.webhookURL+`/end_call_from_hold`,body, { observe: 'response' });
  }

  getNonCallCenterDetailsOutbound(toNumber: string, fromNumber:string): Observable<HttpResponse<DataFilterResponse<callDetailsResponse>>>  {
    return this.http.get<DataFilterResponse<callDetailsResponse>>(this.baseURL+`/call_details/${toNumber}/${fromNumber}/`, { observe: 'response' });
  }

  getSIPCallDetails(body: {from_number: string, sip_id: string} ){
    
    return this.http.post<DataFilterResponse<any>>(this.baseURL+`/outbound_call_sip/`,body);
  }

  getSIPDetailsbyUserName(body: {from_sip: string, to_sip: string} ): Observable<HttpResponse<DataFilterResponse<any>>>{
  
    return this.http.post<DataFilterResponse<any>>(this.baseURL+`/get-agent-details/`,body, { observe: 'response' });
  }

  getCallIdOutbound(noFrom: string, noTo:string ):Observable<HttpResponse<any>>{
    return this.http.get(this.webhookURL+`/get_call_id/${noFrom}/${noTo}`, { observe: 'response' })
  }

  connectToCall(body: { call_sid: string, to_number:string, sip_username:string, call_center_enabled:boolean}): Observable<HttpResponse<DataFilterResponse<any>>>
  {
    return this.http.post<DataFilterResponse<any>>(this.webhookURL+'/connect/call',body, { observe: 'response' })
  }

  //transfer call
  transferCall(body: TransferCallPayload):Observable<HttpResponse<DataFilterResponse<any>>>{
    return this.http.post<DataFilterResponse<{status:boolean,call_transfered_to: string}>>(this.webhookURL+'/transfer/call',body, { observe: 'response' });
  }
}
