import { Routes } from '@angular/router';

export const INNER_LAYOUT_ROUTES: Routes = [
    {
        path: 'org',
        loadChildren: () => import('../../modules/organization/organization.routes').then(c=>c.default)
    },
    {
        path: 'omni-chat',
        loadChildren: () => import('../../modules/omni-chat/omni-chat.routes').then(c=>c.default)
    },
    {
        path: 'phone-system',
        loadChildren: () => import('../../modules/ring2voice/phone-system.routes').then(c=>c.default)
    },
    {
        path: 'customers',
        loadChildren: () => import('../../modules/tenant-customers/companies.routes').then(c=>c.default)
    },
    {
        path: 'ring2voice',
        loadChildren: () => import('../../modules/ring2voice/ring2voice.routes').then(c=>c.default)
    },
];