import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StaticFile, ThumbNail } from '../models/files.models';
import { DataFilterResponse } from '../models/grid-filter.models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService extends BaseService {

  constructor(private http: HttpClient) { super() }

  /*
   ! url need to change as per new api
   */

  uploadFiles(thumbnails: ThumbNail[], module_name: string,tenant:string='2',suborg:string='1'): Observable<HttpResponse<DataFilterResponse<any>>> {

    let formData = new FormData();

    for (let i = 0; i < thumbnails.length; i++) {
        formData.append("files", thumbnails[i].file);
    }

    formData.append('module', module_name); 
    formData.append('tenant', tenant);
    formData.append('suborg', suborg);

    return this.http.post<DataFilterResponse<any>>(`${this.baseURL}/static/files/upload`, formData,{ observe: 'response' }).pipe(
        map((response: HttpResponse<DataFilterResponse<any>>) => {
          return response;
        })
    );
}



  getFiles(moduleName: string) {
    return this.http.get<StaticFile>(`${this.baseURL}/static/files/modulename/${moduleName}`)
  }

  deleteFile(id: any) {
    return this.http.delete<any>(`${this.baseURL}/static/files/${id}/delete/`);
  }
}
