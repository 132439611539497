import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { AppConstants } from '../constants/app.constants';
import { AuthResponse, IResetPasswordRequest, UserPrivileges } from 'app/modules/auth/models/auth-response';
import { SystemRoles, UserTypes } from '../enums/user-types';
import { BaseService } from '../services/base.service';
import { NavigationExtras, Router } from '@angular/router';
import { TwoFactorTypes } from '../enums/common.enum';
import { SubOrg } from 'app/modules/suborg/models/suborg.model';
import { DataFilterResponse, DataResponse } from '../models/grid-filter.models';

@Injectable({providedIn: 'root'})
export class AuthService extends BaseService
{
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _router = inject(Router);

    private statesUrl = '../../assets/json/states.json';

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        // if ( this._authenticated )
        // {
        //     throw new Error('User is already logged in.');
        // }

        return this._httpClient.post(`${this.baseURL}/token/`, credentials).pipe(
            switchMap((response: any) =>
            {
                if(response.data)
                    // Set the authenticated flag to true
                    this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post(`${this.baseURL}/forgotpassword/email/`, { email: email });
    }

    /**
     * Reset password
     *
     * @param request
     */

    resetPassword(request: any): Observable<HttpResponse<DataFilterResponse<any>>> {
        return this._httpClient.post<DataFilterResponse<any>>(
            `${this.baseURL}/forgotpassword/activate/`, request,{ observe: 'response' } 
        ).pipe(
            map((response: HttpResponse<DataFilterResponse<any>>) => {
                return response;
            }))
    }

    resetOwnPassword(request: any): Observable<HttpResponse<DataResponse<any>>>
    {
        return this._httpClient.post<DataResponse<any>>(`${this.baseURL}/resetpassword/`, request, { observe: 'response' })
        .pipe(
        	
            map((response: HttpResponse<DataResponse<any>>) => {
           return response;
       }))
   
    }

    resetUserPassword(request: IResetPasswordRequest) : Observable<HttpResponse<DataResponse<any>>>
    {
        return this._httpClient.post<DataResponse<any>>(`${this.baseURL}/resetuserpassword/`, request, { observe: 'response' })
        .pipe(
        	
            map((response: HttpResponse<DataResponse<any>>) => {
           return response;
       }))
    }

    /**
     * Verify Email
     *
     * @param userid, code, secret
     */
    verifyEmail(userid: number, code: string, secret: string): Observable<any>
    {
        return this._httpClient.post<{ valid: boolean, auth: AuthResponse }>(`${this.baseURL}/users/${userid}/email_verification/${secret}/`, { otp: code });
    }

    /**
     * Sign out
     */
    signOut(user_id: number, device_id: string): Observable<HttpResponse<DataFilterResponse<any>>> {
        // Make the API call
        return this._httpClient.post<DataFilterResponse<any>>(
            `${this.baseURL}/users/${user_id}/logout/`, 
            { device_id }, 
            { observe: 'response' } // Observe the full HTTP response
        ).pipe(
            map((response: HttpResponse<DataFilterResponse<any>>) => {
                // On success, perform necessary cleanup
                this._authenticated = false; // Set the authenticated flag to false
    
                // Return the full HTTP response
                return response;
            })
        );
    }
    // signOut(user_id:number, device_id:string): Observable<any>
    // {
    //     // Remove the access token from the local storage
    //     // localStorage.removeItem('accessToken');
    //     return this._httpClient.post(`${this.baseURL}/users/${user_id}/logout/`, {device_id:device_id}).pipe(
    //         switchMap((response: any) =>
    //         {
    //              // Set the authenticated flag to false
    //             this._authenticated = false;

    //             // Return a new observable with the response
    //             return of(response);
    //         }),
    //     );
    // }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Send Email vaerification code
     */
    // sendEmailVerificationCode(userid: number, secret: string) {
    //     return this._httpClient.get<DataFilterResponse<any>>(`${this.baseURL}/users/${userid}/email_verification/${secret}/`);
    // }
    sendEmailVerificationCode(userid: number, secret: string): Observable<HttpResponse<DataFilterResponse<any>>> {
        return this._httpClient.get<DataFilterResponse<any>>(
            `${this.baseURL}/users/${userid}/email_verification/${secret}/`,
            { observe: 'response' } // Observe the full HTTP response
        ).pipe(
            map((response: HttpResponse<DataFilterResponse<any>>) => {
                // Return the full HTTP response for further processing
                return response;
            }));
    }

    /**
     * Send two factor authentication OTP
     */
    send2faOtp(request: { userid: number, type: TwoFactorTypes, secret?: string }) {
        return this._httpClient.post(`${this.baseURL}/two_factor/otp/send/`, request);
    }

    /**
     * Verify two factor authentication OTP
     */
    verifyOtp(request: { userid: number, otp: string, type: TwoFactorTypes, secret?: string }) {
        return this._httpClient.post<DataFilterResponse<any>>(`${this.baseURL}/two_factor/otp/verify/`, request);
    }

    verifyUserEmail(userid: number, token: string) {
        return this._httpClient.post<any>(`${this.baseURL}/users/${userid}/verify/`, { token: token });
    }

    getUserStatus(userid: number) {
        return this._httpClient.get<DataFilterResponse<{verified: boolean,active: boolean}>>(`${this.baseURL}/users/${userid}/status/`);
    }

    /**
     * Check for Logged In 
     */

    get isLoggedIn(): boolean {
        return localStorage.getItem(AppConstants.AUTH_TOKEN) !== null;
    }

    /**
     * Get UserType
     */

    getUserType(): UserTypes {
        return localStorage.getItem(AppConstants.USER_TYPE) as UserTypes || UserTypes.User;
    }

    /**
     * Get getUserPrivileges
     */

    getUserPrivileges(token: string) {
        return this._httpClient.get<DataFilterResponse<UserPrivileges>>(`${this.baseURL}/user_privileges/`, {
            headers: new HttpHeaders({ "Authorization": "Token " + token })
        });
    }

    /**
     * Set Session
     * 
     * @param authResp, previleges
     */

    setSession(authResp: AuthResponse, previleges: UserPrivileges): void {

       
        localStorage.setItem(AppConstants.AUTH_TOKEN, authResp.token);
        localStorage.setItem(AppConstants.USER_ID, authResp.userid?.toString());
        localStorage.setItem(AppConstants.TENANT_ID, authResp.tenant_id?.toString());
        localStorage.setItem(AppConstants.USER_AUTH, JSON.stringify(authResp));
        localStorage.setItem(AppConstants.USER_ROLE, authResp.roleid.toString());
        localStorage.setItem(AppConstants.PHONE_NUMBER_COUNT, authResp.phone_number_count?.toString());
        localStorage.setItem(AppConstants.IS_CAMPAIGN_REGISTERED, authResp.is_campaign_exist.toString());
  

        localStorage.setItem(AppConstants.USER_PREVILEGES, JSON.stringify(previleges.resource_privileges));
        // localStorage.setItem(AppConstants.USER_GRIDS, JSON.stringify(previleges.grid_views));

        if (authResp.root) {
            localStorage.setItem(AppConstants.USER_SUBORGS, '[]');
        }
        else if(authResp.tenant_customer_contact_user) // if customer contact
        {
            localStorage.setItem(AppConstants.CURRENT_SUBORG, authResp?.suborg_id?.toString());
        }
        else {
            const suborgs = previleges.suborgs || [];
            if (suborgs.length) {
                if(suborgs.length > 1){
                    const org = new SubOrg();
                    org.id = -1;
                    org.name = 'All';
                    suborgs.unshift(org);
                }
                localStorage.setItem(AppConstants.CURRENT_SUBORG, suborgs[0].id.toString());
            }
            localStorage.setItem(AppConstants.USER_SUBORGS, JSON.stringify(suborgs));
        }

        let userType: UserTypes;

        if (authResp.root) {
            userType = UserTypes.SuperUser;
        }else if(authResp.is_tenant_user){
            userType = UserTypes.TenantUser;
        }else {
            userType = authResp.rolename === SystemRoles.Admin ? UserTypes.Admin :authResp.tenant_customer_contact_user ? UserTypes.Customer  : UserTypes.User;
        }
        localStorage.setItem(AppConstants.USER_TYPE, userType);
    }

    
    navigate(firstLogin: boolean = false): void {
        const userType = this.getUserType();

        if (firstLogin) {
            this._router.navigate(['/phone-system/dids/new']);
        }else{
            this._router.navigate(['/org/users']);
        }

        // if (userType == UserTypes.SuperUser) {
        //     this._router.navigate(['/org/users']);
        // }
        // else if (userType === UserTypes.Admin) {
        //     this._router.navigate(['/org/users']);
        // }
        // else 
        // {
        //     this._router.navigate(['/users/my-profile']);
        // }
    }

    // Signup for new user
    checkEmailExists(email: string):Observable<HttpResponse<DataFilterResponse<any>>>  {
        return this._httpClient.post<DataFilterResponse<{exists: boolean}>>(`${this.baseURL}/tenant/email/check/`, { email: email },
            { observe: 'response' }
        ).pipe(
            map((response: HttpResponse<any>) => {
                // Return the full HTTP response for further processing
                return response;
            }))
    
    }

    sendSignUpOTP(body: any): Observable<HttpResponse<any>>{
        return this._httpClient.post<any>(`${this.baseURL}/tenant/send-otp/`, body,
            { observe: 'response' }).pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                }))   
    }

    verifySignUpOTP(email: string, otp: string): Observable<HttpResponse<any>> {

        return this._httpClient.post<DataFilterResponse<any>>(`${this.baseURL}/tenant/verify/email/`,
             { email: email, otp: otp },{ observe: 'response' } ).pipe(
                map((response: HttpResponse<any>) => {
                    // Return the full HTTP response for further processing
                    return response;
                }));
    }

    // userSignUp(id:number, body: any){
    //     return this._httpClient.post<any>(`${this.baseURL}/tenant/card/${id}/`, body);
    // }

    userSignUp(id: number, body: any): Observable<HttpResponse<any>> {
        return this._httpClient.post<any>(`${this.baseURL}/tenant/card/${id}/`,body,
            { observe: 'response' } // Observe the full HTTP response
        ).pipe(
            map((response: HttpResponse<any>) => {
                // Return the full HTTP response for further processing
                return response;
            }));
    }
    getCountrys():Observable<HttpResponse<DataFilterResponse<any>>> {
        return this._httpClient.get<DataFilterResponse<any>>(`${this.baseURL}/country/`, { observe: 'response' });
    }

    getStatesByCountryId(id:number):Observable<HttpResponse<DataFilterResponse<any>>> {
        return this._httpClient.get<DataFilterResponse<any>>(`${this.baseURL}/country/${id}/states/`, { observe: 'response' });
    }
}
