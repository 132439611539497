<ng-select
    [(ngModel)]="value"
    [items]="states"
    (change)="onChangeEvent($event)"
    placeholder="Select state"
    bindLabel="name"
    appendTo="body"
    [trackByFn]="trackByFn"
    [readonly]="disabled"
>
</ng-select>