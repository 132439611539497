import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CompanyService } from 'app/modules/tenant-customers/services/company.service';
import { SubSink } from 'subsink';
import { CustomerSelectComponent } from 'app/shared/components/customer-select/customer-select.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonService } from 'app/core/services/common.service';
import { AppEventType, CommonPanelEvents } from 'app/core/enums/common.enum';
import { DataFilterRequest } from 'app/core/models/grid-filter.models';
import { CompanyUser } from 'app/modules/tenant-customers/models/companies.models';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from 'app/core/auth/auth.service';
import { TimezoneSelectComponent } from 'app/shared/components/timezone-select/timezone-select.component';
import { ThumbNail } from 'app/core/models/files.models';
import { FilesService } from 'app/core/services/files.service';
import { environment } from 'environments/environment';
import { AppEvent } from 'app/core/models/common.models';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { ImageCropperDirective } from 'app/shared/directives/image-cropper.directive';
import { Subject } from 'rxjs';
import { CallCenterSharedService } from 'app/modules/ring2voice/services/call-center-shared.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { PhoneNumberFormatDirective } from 'app/shared/directives/phone-number-format.directive';
import { PhoneValidatorDirective } from 'app/shared/directives/phone-validator.directive';
import { StateSelectComponent } from 'app/shared/components/state-select/state-select.component';

@Component({
  selector: 'company-user-add',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatButtonModule, NgSelectModule,
    MatSelectModule, MatInputModule, CustomerSelectComponent, MatTooltipModule, TimezoneSelectComponent,
    ImageCropperDirective, PhoneValidatorDirective,PhoneNumberFormatDirective, StateSelectComponent],
  templateUrl: './company-user-add.component.html',
  styleUrl: './company-user-add.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('in', style({
        opacity: 1, 
        transform: 'translateY(0)'
      })),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }),
        animate('0.5s ease-out')
      ]),
      transition('* => void', [
        animate('0.5s ease-in', style({
          opacity: 0,
          transform: 'translateY(-20px)'
        }))
      ])
    ])
  ]
})
export class CompanyUserAddComponent implements OnInit, OnDestroy {

  public submitted: boolean = false

  public itemForm: FormGroup;

  @Output() close: EventEmitter<boolean> = new EventEmitter();

  @Output() onSave: EventEmitter<any> = new EventEmitter();

  private subs: SubSink = new SubSink();

  formFieldHelpers: string[] = [''];

  // private idVal: string;
  // @Input() set id (val: string) {
  //   if(val)
  //   {
  //     this.idVal = val;
  //   }
  // }
  @Input() id: number

  public showAdditional_phone: boolean = false

  public showAdditional_mail: boolean = false

  public addNew_Company: boolean = false

  @Input() contactDetails: any

  @Input() phone: string

  @Output() onDelete: EventEmitter<CompanyUser> = new EventEmitter();

  @Input() hideCompany: boolean = false

  suborgId: number;

  thumbNail: ThumbNail;

  isImageUpload: boolean = false;

  fileUrl: string = environment.imgURL;

  public storeData$ = new Subject<any>();


  constructor(private _fb: FormBuilder, private _tenantCustomerService: CompanyService,
    private _toaster: ToastrService, _route: ActivatedRoute, private _commonService: CommonService,
    private _callcenterShared: CallCenterSharedService, private _authService: AuthService,
    private companyService: CompanyService, private _cdr: ChangeDetectorRef,
    private fileService: FilesService, private commonservice: CommonService
  ) {
    this.suborgId = this._tenantCustomerService.suborgId;
    //this.id = Number(_route.snapshot.paramMap.get('id')) || null;
  }

  ngOnInit(): void {

    console.log('temp_data', this._callcenterShared.contactSaveTempData)
    const tempData = this._callcenterShared.contactSaveTempData

    if (tempData?.id) {
      this.id = tempData?.id
    }

    this.itemForm = this._fb.group({
      id: [null],
      company: [null],
      tenant: [this._tenantCustomerService.tenantId],
      suborg: [this.suborgId],
      first_name: ['', [Validators.required]],
      last_name: [null],
      phone: [''],
      mobile: [''],
      email: [null, [Validators.email]],
      company_det: [],
      image: [null],
      company_details: this._fb.group({
        name: [''],
        phone_1: [''],
        state: [null],
        city: [''],
        zip_code: [''],
        address: [''],
        website: ['', Validators.pattern(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/)],
        suborg: [this.suborgId],
      }),
      designation: [null],
      additional_information: [''],
      state: [null],
      city: [''],
      zip_code: [''],
      time_zone: [Intl.DateTimeFormat().resolvedOptions().timeZone],
      address: [''],
      secondary_email: [null]
    })
    this.subscribeEvents()
    this.itemForm.valueChanges.subscribe(value => {
      if (this.itemForm.controls['id'].value == null) {
        this._callcenterShared.contactSaveTempData = value
      }
    });

    if(tempData)
    {
      this.itemForm.patchValue(tempData)
      if (tempData?.company_details) {
        this.itemForm.controls['company_det'].setValue([{ _id: tempData.company_details.id, name: tempData.company_details.name }])
        this.itemForm.controls['company_details'].patchValue(this._callcenterShared.contactSaveTempData.company_details)
      }
      if (tempData?.mobile) {
        this.showAdditional_phone = true
      }
      if (tempData?.secondary_email) {
        this.showAdditional_mail = true
      }
    }
    else if (this.contactDetails) {

      this.itemForm.patchValue(this.contactDetails)
      this.id = this.contactDetails?.id
      if(this.contactDetails?.image_id){
        this.itemForm.controls['image'].setValue(this.contactDetails?.image_id)
      }
      if (this.contactDetails?.company > 0) {
        this.getCompanyDetails(this.contactDetails.company);
    }
      if (this.contactDetails.mobile) {
        this.showAdditional_phone = true
      }
      if (this.contactDetails.secondary_email) {
        this.showAdditional_mail = true
      }

    }
    else if(this.id){
      this.getContactInfoById(this.id)
    }

    if (this.phone) {
      this.itemForm.controls['phone'].setValue(this.phone)
    }

    this.storeData$.subscribe(res => {
      console.log('res_contact', res.data.result[0])
      this.itemForm.patchValue(res.data.result[0])
    })



    this.itemForm.get('company_details')?.disable();

    this.getData()

   
  }

  get companyDetailsGroup() {
    return this.itemForm.get('company_details') as FormGroup;
  }
  getData() {
    this.subs.sink = this.storeData$.subscribe(res => {
      if (res.data?.result) {
        this.itemForm.patchValue(res.data.result[0])
        if (res.data.result[0].company > 0)
          this.itemForm.controls['company_det'].setValue([{ _id: res.data.result[0].company, name: res.data.result[0].company_name }])
        if (res.data.result[0]?.mobile) {
          this.showAdditional_phone = true
        }
        if (res.data.result[0]?.mobile) {
          this.showAdditional_phone = true
        }
        if (res.data.result[0]?.secondary_email) {
          this.showAdditional_mail = true
        }
        if (res.data.result[0].company > 0) {
          this.getCompanyDetails(res.data.result[0].company)
        }

      }
    })

  }


  subscribeEvents() {
    this.subs.sink = this._commonService.on(AppEventType.SuborgSwitch).subscribe(event => {
      this.suborgId = this._tenantCustomerService.suborgId
    });
  }

  onCloseContact() {
    this._callcenterShared.contactSaveTempData = null;
    this.closeContactPanel();
  }

  closeContactPanel() {
    const sidePanelConstants: sidePanelConstants = { EventName: CommonPanelEvents.ClosePanel }
    this.commonservice.dispatch(new AppEvent(AppEventType.CommonPanelEvent, sidePanelConstants));
    this.close.emit(true);
  }

  removeProfileImg() {
    this.thumbNail?.url ? this.thumbNail.url = null : null;
    this.thumbNail?.file ? this.thumbNail.file = null : null;
    this.itemForm.controls['image'].setValue(null);
  }

  handleCroppedImage(result: any) {
    if (result) {
      this.isImageUpload = true;
      this.thumbNail = new ThumbNail();
      this.thumbNail.file = result.file;
      this.thumbNail.url = result.url;
    }
  }

  onSubmit() {

    if (this.isImageUpload && this.thumbNail?.file) {
      this.subs.add(
        this.fileService.uploadFiles([this.thumbNail], 'USER_GROUP').subscribe({
          next: (response) => {
            const statusCode = response.status; 
            const responseBody = response?.body;
            if (statusCode == 200 && responseBody.data?.result) {
              this.itemForm.patchValue({ 'image': responseBody.data?.result[0].id })
              this.isImageUpload = false;
              this.onFormSubmit();
            }
            else {
              this._toaster.error(responseBody.message[0]);
              window.scrollTo(0, 0);
            }
          },
          error: (error) => {
            this._toaster.error("Error occured while uploading image");
            window.scrollTo(0, 0);
          },
          complete: () => {
          }
        }));
    } else {
      this.onFormSubmit();
    }
  }

  onFormSubmit() {

    this.submitted = true;
    console.log('isvalid', this.itemForm.valid)
    console.log('formval', this.itemForm.getRawValue())

    if (!this.itemForm.valid) {
      return;
    }

    if (this.itemForm.controls['suborg'].value == -1) {

      this._toaster.warning("Please select SubOrg before saving");
      return
    }


    // if(this.itemForm.controls['compa'].value == null){

    this.setNullValuesIfEmpty();

    const body = this.itemForm.getRawValue();
    const companyDetailsControls = this.itemForm.get('company_details') as FormGroup;
    if ((companyDetailsControls.get('name').value == null || companyDetailsControls.get('name').value == '') &&
      (this.itemForm.controls['company'].value == null || this.itemForm.controls['company'].value == '')) {

      body.company_details = null;
    }

    let api: any = this.id ? this._tenantCustomerService.updateCustomerContact(this.id.toString(), body)
      : this._tenantCustomerService.createCustomerContact(body);

    this.subs.sink = api.subscribe(
      response => {
        const statusCode = response.status; 
	      const responseBody = response?.body;
        if (statusCode == 200) {
          if (!this.id) {
            //this.resetForm()
          }

          let msg = this.id ? `Contact info updated` : `New contact created`;
          this._toaster.success(msg);
          this._callcenterShared.contactSaveTempData = null;
          this.onSave.emit({ saveInfo: responseBody.data.result, newCompanyDet: body?.company > 0 ? null : body.company_details });

        }
        else {
          this._toaster.error(responseBody.message[0]);
        }
        this.submitted = false

      },
      error => {
        this.submitted = false
        this._toaster.error("Error on save data")
      }
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  fupOnChange(event: any) {
    this.prepareFileList(event.target.files);
  }

  prepareFileList(files: Array<any>) {
    if (files && files.length > 0) {
      this.isImageUpload = true;
      var reader: FileReader = new FileReader();
      reader.onload = ((file: any) => {
        return (event: any) => {
          this.thumbNail = new ThumbNail();
          this.thumbNail.url = event.target.result;
          this.thumbNail.file = file;
          this.thumbNail.name = file?.name;
          this.thumbNail.type = file?.type;
          this._cdr.markForCheck();
        }
      })(files[0]);
      reader.readAsDataURL(files[0]);
    }
  }

  getContactInfoById(id: number) {
    let request: DataFilterRequest = {

      filters: [{
        conditions: [
          {
            "colname": "id",
            "condition": "equalto",
            "value": id.toString(),
            "operator": "AND"

          }], "operator": "AND"
      }],
      sort: [],
      base_filters: []
    };
    this.subs.sink = this._tenantCustomerService.getCompanyUserById_LocalDB(this.storeData$, request, 1, 0)
  }

  setCustomer(item: any) {
    this.itemForm.controls['company'].setValue(item[0]._id)
    this.itemForm.controls['company_det'].setValue([{ _id: item[0]._id, name: item[0].name }])
  }

  removeAdditionalField(type: 'mobile' | 'secondary_email' | 'company') {
    if (type == 'mobile') {
      this.showAdditional_phone = false;
      this.itemForm.controls['mobile'].setValue(null);
    } else if (type == 'secondary_email') {
      this.showAdditional_mail = false;
      this.itemForm.controls['secondary_email'].setValue(null);
    }
    else if (type == 'company') {
      this.addNew_Company = false;
      this.itemForm.get('company_details')?.disable();
      this.itemForm.get('company_details')?.reset({
        name: null,
        phone_1: null,
        state: null,
        city: null,
        zip_code: null,
        address: null,
        website: null,
        suborg: null
      });
      this.itemForm.controls['company'].setValue(null);
      this.itemForm.controls['company_det'].setValue([]);
    }
  }

  // toggleAdditionalPhone()
  // { 
  //   this.showAdditional_phone = !this.showAdditional_phone
  // }

  // toggleadditionalMail()
  // {
  //   this.showAdditional_mail = !this.showAdditional_mail
  // }

  setNullValuesIfEmpty() {
    Object.keys(this.itemForm.controls).forEach(key => {
      if (this.itemForm.get(key).value === '') {
        this.itemForm.get(key).setValue(null);
      }
    });
  }

  onTimezoneChange(event: any) {
    this.itemForm.patchValue({ 'time_zone': event });
    this.itemForm.updateValueAndValidity();
  }

  onStateChange(event: any, type: 'company'|'contact'){
    if(type=='company'){
      this.companyDetailsGroup.patchValue({'state': event?.name||null});
    }else{
      this.itemForm.patchValue({'state': event?.name||null});
    }
    this.companyDetailsGroup.updateValueAndValidity();
  }

  setCompany(item: any) {
    if (item && item[0] && item[0]._id) {
      this.itemForm.controls['company'].setValue(item[0]._id);
      this.itemForm.controls['company_det'].setValue([{ _id: item[0]._id, name: item[0].name }])
      this.getCompanyDetails(item[0]._id)
    } else {
      this.resetCompanyDetails()
    }
  }

  resetCompanyDetails() {
    this.itemForm.controls['company'].setValue(null);
    this.itemForm.controls['company_det'].setValue([])
    this.itemForm.get('company_details').reset({
      name: '',
      phone_1: '',
      state: '',
      city: '',
      zip_code: '',
      address: '',
      website: '',
      suborg: this.suborgId
    });
  }
  getCompanyDetails(id: number) {
    this.companyService.getCompanyById(id).subscribe({
      next: response => {
        const statusCode = response.status; 
	      const responseBody = response?.body;
        if (statusCode == 200) {
          const data = responseBody.data?.result;
          this.itemForm.get('company_details')?.patchValue(data);
          this.itemForm.controls['company_det'].setValue([{ _id: data.id, name:data.name }])
        } else {
          this._toaster.error("Failed to fetch company details");
        }
      },
      error: (error) => {
        console.error(error.error);
        this._toaster.error("Failed to fetch company details");
      }
    })
  }
  onAddNewCompany() {
    this.addNew_Company = true
    this.itemForm.get('company_details')?.enable();
  }

  delete() {
    this.onDelete.emit(this.itemForm.getRawValue())
  }

  ngondestroy(): void {
    this.subs.unsubscribe();
  }


}
